
.footer {
  padding: 20px 40px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  bottom: 9px;
  width: 100%;

  @media (max-width: 900px) {
    // flex-direction: column;
    flex-direction: column;
    padding: 10px 10px;
  }

  .rights {
    color: white;
    text-align: right;
    margin: auto 0 auto auto;
  
    @media (max-width: 900px) {
      margin: auto;
      margin-bottom: 10px;
    }
  }

  img {
    @media (max-width: 900px) {
      margin-bottom: 10px;
    }
  }

  @media (min-width: 900px) {
    img {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}