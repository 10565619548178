@import url('https://fonts.googleapis.com/css?family=Rubik+Mono+One');

// body {
//     font-family: Plus Jakarta Sans !important;
// }

.supply {
    font-family: 'Rubik Mono One', sans-serif;
}

.MuiToolbar-root {
    @media (min-width: 600px) {
        min-height: 60px !important;
        padding-left: 40px !important;
    }

    
    @media (min-width: 1200px) {
        min-height: 70px !important;
        padding-left: 40px !important;
    }


}

// .MuiAppBar-positionFixed {
//     @media (orientation: landscape) and (max-height: 550px) {
//         // padding-top: 0rem;
//         position: unset !important;
//     }
// }

.genfinity-team {
    height: 100%;
}

.network-switcher-select {
    color: white;
}
.network-switcher {
    padding: 0 8px;

    .MuiInputBase-root {
        color: white;
    }
    .MuiOutlinedInput-root {
        border-color: white !important;
    }

    .network-switcher-select {
        padding: 0 8px;
    }

    #network-switcher-select {
        padding: 8px 20px 8px 0 !important;
        display: flex;
        align-items: center;
    }
}

.network-option {
    padding: 6px 16px !important;
}

.bg {
    opacity: 0.4;
    position: absolute;
    width: 90%;
    max-width: 600px;
    height: 100%;
    max-height: 600px;
    z-index: 1;
    margin: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -45%);
}

.content {
    position: relative;
}

.launch-schedule {
    width: fit-content;
}

.flex {
    display: flex;
    flex-direction: row;
}

.landing-page {
    align-items: center;
    display: flex;
    flex-direction: row;
    padding: .5rem 0rem 0 0rem;

    @media (min-width: 600px) {
        padding: 3rem 2rem 0 2rem;
    }

}

.disclaimer-dialog {
    // max-width: 700px;
    
    .disclaimer-title {
        text-align: center;
        font-family: Plus Jakarta Sans !important;
        font-size: 1.6rem;
        margin-bottom: 1rem;
    }
    
    .disclaimer-message {
        font-family: Plus Jakarta Sans !important;
        font-size: .8rem;
        text-align: center;

        @media (min-width: 700px) {
            font-size: 1rem;
        }
    }
}

.settings-dialog {
    .MuiListItemText-root {
        margin-right: 3rem;
    }
}

.hidden-nft-text {
    @media (max-width: 700px) {
        max-width: 45%;
        white-space: normal;
    }
}

.view-tab {
    min-width: unset !important;
}

.homepage-wrapper {
    margin: 20px 0px 40px 0px;
    justify-content: center;
    text-align: center;
    width: 90vw;
    max-width: 1200px;
    margin: auto;

    @media (max-width: 700px) {
        width: fit-content;
        margin: auto;
    }
}