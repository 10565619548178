li  {
    padding: 10px;
}
li:first-line {
    padding-left:0px;
}

.staking-pools {
    @media (max-width: 900px) {
        margin: 5px 0px !important;
        padding: 10px;
        text-align: center;
    }
}

.beta-test {
    position: absolute;
    top: 100px;
    right: 50px;
    width: 125px;
    height: 125px;

    @media (max-width: 1300px) {
        top: 75px;
        right: 20px;
        width: 100px;
        height: 100px;
    }

    @media (max-width: 1000px) {
        width: 75px;
        height: 75px;
        top: 75px;
        right: 0px;
    }

    @media (max-width: 800px) {
        width: 75px;
        height: 75px;
        top: 75px;
        right: 10px;
    }


    @media (max-width: 500px) {
        width: 60px;
        height: 60px;
        top: 60px;
        right: 0px;
    }
}

.social-hub {
    font-family: Plus Jakarta Sans;
    margin: 4rem 0 3rem 0;
    font-size: 1.4rem;
    letter-spacing: .7rem;
    font-weight: 200;
    text-transform: uppercase;

    @media (max-width: 700px) {
        margin: 1rem 0 1rem 0;
        font-size: 1rem;
        letter-spacing: .4rem;
    }
}