.user-list-dialog {
    min-width: 300px;
    min-height: 500px;  
    // background: var(--accent-background) !important;

    .MuiPaper-root {
        background-color: unset;
        
        .MuiTabs-root {
            margin: auto;
        }
    }

    .mobile-scroll {
        max-height: calc(100vh - 79px);
        overflow: scroll;
    }

    .user {
        align-items: center;
        display: flex;
        font-size: 1rem;
        font-weight: 500;
        padding: 0 .5rem 1.2rem .5rem;

        .MuiAvatar-root {
            width: 60px;
            height: 60px;

            margin-right: 1rem;
        }
    }
}

.full-height {
    flex-grow: 1    
}