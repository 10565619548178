.confirmation-wrapper {
  font-family: Plus Jakarta Sans;
  margin: 3rem auto;
  width: fit-content;
  // text-transform: uppercase;
  padding: 1.5rem 1rem;
  border-radius: 20px;
  text-align: center;
  line-height: 2.8rem;
  width: 90%;
  max-width: 750px;
  text-align: center;

  @media (max-width: 700px) {
    padding: 0;
    margin: 2rem auto;
  }

  .confirmation {
    margin: auto;
    border: 1px solid gray;
    border-radius: 30px;
    display: flex;
    border-radius: 80px;
    align-items: center;
    max-width: 700px;
    
    @media (max-width: 700px) {
      margin: 10px;
      flex-direction: column;
      border-radius: 20px !important;
      padding: 1rem;
    }

    .main-text {
      font-size: 3rem;
      font-weight: 700;
      margin-bottom: 5px;

      @media (max-width: 700px) {
        font-size: 2.5rem;
        line-height: 2rem;
        margin: auto;
        margin-top: .5rem;
      }
    }

    .sub-text {
      font-weight: 600; 
      margin-top: .6rem;
      line-height: 1.2rem !important;
      letter-spacing: .1rem !important;
      margin-bottom: .4rem;
      text-align: center;

      @media (max-width: 700px) {
        text-align: center;
        font-size: .6rem !important;
        line-height: 2rem;
      }
    }
  }
}


.icon-wrapper {
  font-family: Plus Jakarta Sans;
  height: 130px;
  width: 130px;
  border: 1px solid black;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: black;
  color: #FF6565;
  opacity: 1;
  z-index: 10000;
  
  @media (max-width: 700px) {
    height: 100px;
    width: 100px;
  }

  .icon {
    // font-size: 4rem;
    font-weight: 800;
    line-height: 1.5rem;

    @media (max-width: 700px) {
      font-size: 1.5rem;
      font-weight: 800;
      line-height: .8rem;
    }
  }
}