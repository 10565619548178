.lighthouse-login-form {

  margin: 0 auto;
  margin-bottom: 3rem;
  min-height: 500px;
  min-width: 350px;
  max-width: 900px;
  border-radius: 20px;
  padding: 3rem;
  width: 80%;
  border: 2px solid #FF6565;

  @media (max-width: 700px) {
    margin: 3rem;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
  }
  
  @media (max-width: 500px) {
    margin: 1rem auto 2rem;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    padding: 1rem;
  }
  
  .lighthouse-login-content {
    margin: 0 0 2rem;
    text-align: center;
    display: flex;
    flex-direction: column;
  }
}