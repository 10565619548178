.main-content {
    margin-top: 60px;
    height: calc(100% - 60px);
    display: flex;
    flex-direction: column;
    
    // @media (max-width: 900px) {
    //     margin-top: 2rem;
    //     height: calc(100% - 2rem);
    // }
    .new-post {
        position: fixed;
        bottom: 0;
        right: 0;
        margin: 30px;
    }
}