.MuiDialog-paper {
    // background: var(--accent-background) !important;
}

.image-holder, .image-holder-loaded {
    margin: auto;
}
.container {
    max-width: 900px;
    padding: 32px 15px 0;
    margin: 0px auto;
    box-sizing: border-box;
    width: 100%;
}
.post-dialog-paper {

    .MuiPaper-root {
        border-radius: 12px;
    }

    .post-dialog {
        width: 50rem;
        min-height: 500px;
        max-width: 100%;
        max-height: 100%;
        align-self: center;
        
        .MuiDialogTitle-root {
            text-align: center;
    
            .MuiTypography-root  {
                font-size: 30px;
            }
        }
    
        .MuiAvatar-root {
            border-radius: unset;
            width: 300px;
            height: 300px;
        }
    
        .post-fields {
            display: flex;
            flex-direction: column;
    
            margin: 30px 0 0 0;
            
            .MuiTextField-root {
                margin: 10px 0;
            }
        }
    
        .dialog-buttons {
            display: flex;
            justify-content: flex-end;
                
            .cancel-button {
                margin-right: 1rem;
            }
        }
    }
}
