.desktop-logo {
    display: none;
    @media (min-width: 600px) {
        display: block;
    }
}

.mobile-logo {
    display: block;

    @media (min-width: 600px) {
        display: none;
    }

}

.header-button {
    font-size: 1rem !important;

    @media (max-width: 700px) {
        font-size: .8rem !important;
    }
}
.user-search {
    width: 150px;

    @media (min-width: 500px) {
        width: 200px;
    }

    @media (min-width: 900px) {
        width: 300px;
    }
}

.MuiAutocomplete-popper {
    min-width: 200px !important;
    @media (max-width: 700px) {
        width: fit-content !important;
    }
    position: absolute !important;

    // @media (min-width: 700px) {
    //     width: inherit !important;
    // }
}

.footer {
    position: fixed;
    top: auto !important;
    bottom: 0 !important;

    .MuiToolbar-root {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: auto;

        @media (min-width: 500px) {
            width: 450px;
        }
    
        @media (min-width: 700px) {
            width: 600px;
        }

        @media (min-width: 900px) {
            width: 800px;
        }
    }
}

.nav-bar-buttons {
    
    @media (min-width: 700px) {
        button {
            margin: 0 20px;
        }
    }
    
    .dropdown-item {
        span {
            text-align: center;
            margin: auto;
            padding: 4px 16px 4px 16px;
        }
    }
}