body {
  /* height: 100vh; */
  margin: 0;
  padding: 0;
  font-family: Plus Jakarta Sans;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: black !important; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
}

@font-face {
  font-family: 'Karasha';
  src: local('Karasha'), url(./fonts/Karasha.ttf) format('truetype');
}


@font-face {
  font-family: 'Shapiro';
  src: local('Shapiro'), url(./fonts/Shapiro105.otf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./fonts/Roboto.ttf) format('truetype');
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: local('Plus Jakarta Sans'), url(./fonts/PlusJakartaSans.ttf) format('truetype');
}

@font-face {
  font-family: 'Plus Jakarta Sans Light';
  src: local('Plus Jakarta Sans Light'), url(./fonts/PlusJakartaSans-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Plus Jakarta Sans Extra Bold';
  src: local('Plus Jakarta Sans Extra Bold'), url(./fonts/PlusJakartaSans-ExtraBold.ttf) format('truetype');
}


@font-face {
  font-family: 'SF Pro Display Bold';
  src: local('SF Pro Display Bold'), url(./fonts/SFPRODISPLAYBOLD.OTF) format('truetype');
}

@font-face {
  font-family: 'SF Pro Display';
  src: local('SF Pro Display'), url(./fonts/SFPRODISPLAYREGULAR.OTF) format('truetype');
}

@font-face {
  font-family: 'NeueHaasDisplayBlack';
  src: local('NeueHaasDisplayBlack'), url(./fonts/NeueHaasDisplayLight.ttf) format('truetype');
}

@font-face {
  font-family: 'Orpheus Pro';
  src: local('Orpheus Pro'), url(./fonts/Orpheuspro.otf) format('truetype');
}

@import url(//db.onlinewebfonts.com/c/8bf03e662b63d69e50bb114b6bd9a60b?family=OrpheusW05-Regular);
