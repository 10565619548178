.visme {
  max-width: 1600px !important;
  max-height: 1000px !important;

  @media (max-width: 1000px) {
    height: 100vh !important;
  }

  @media (max-width: 600px) {
    height: 100vw !important;
  }
}

.free-edition {
  font-size: 3.2rem;
  font-family: Plus Jakarta Sans;
  font-weight: 300;
  letter-spacing: 1rem;
}

.contributors-description {
  font-size: 2rem;
  margin-bottom: 3rem; 
  letter-spacing: .5rem;

  @media (max-width: 700px) {
    font-size: 1.2rem;
    margin-bottom: 0;
    letter-spacing: .2rem;
  }
}

.header-text {
  align-self: center;

  @media (min-width: 700px) {
    max-width: 40%;
  }

  @media (min-width: 1300px) {
    max-width: 50%;
  }
}

.lighthouse-header {
  padding: 40px 30px 40px 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // justify-content: space-evenly;
  margin: auto;
  margin-bottom: 30px;
  // align-items: center;
  margin-top: -5px;
  // max-height: 400px;

  @media (max-width: 700px) {
    margin-bottom: 0px;
    flex-direction: column;
    align-content: center;
    padding: 20px 0px 0px 0px;
    margin-top: -10px;
  }

  @media (min-width: 700px) {
    padding: 40px 0px 60px 30px;
  }

  .lighthouse-image {
    width: 25%;
    padding: 0 1rem;
    text-align: center;
    align-self: center;


    @media (max-width: 700px) {
      width: unset;
      align-self: center;
    }

    .image {
      max-width: 200px;

      @media (max-width: 1000px) {
        width: unset;
        align-self: center;
        max-width: 160px;
      }

      @media (max-width: 700px) {
        max-width: 100px;
      }
    }
  }

  .header-text {
    color: white;
    text-align: center;
    align-items: center;
    max-width: 50%;
    flex-grow: 1;

    @media (max-width: 700px) {
      max-width: 90%;
      align-self: center;
    }
  }

  .lighthouse-title {
    font-size: 6.5rem;
    margin-bottom: .2rem;
    font-family: 'Orpheus Pro';

    @media (max-width: 1000px) {
      font-size: 4rem;
    }

    @media (max-width: 700px) {
      align-self: center;
    }
  }
  .lighthouse-subtitle {
    font-size: 1.2rem;
    margin-bottom: .5rem;
    font-weight: 500;
    font-family: 'Orpheus Pro';
    color: lightgray;

    @media (max-width: 1000px) {
      font-size: 1rem;
    }

    @media (max-width: 700px) {
      font-size: .9rem;
    }
    @media (max-width: 1300px) {

    }
  }
  .lighthouse-description {
    font-size: 1.2rem;
    font-family: 'NeueHaasDisplayBlack';
    // font-weight: 300;
    max-width: 700px;
    text-align: center;
    margin: auto;
    color: lightgray;
    @media (max-width: 1000px) {
      font-size: 1rem;
    }

    @media (max-width: 1300px) {

    }
  }

  .hedera-block {
    width: 25%;

    @media (max-width: 700px) {
      align-self: center;
      width: 175px;
    }
    
    .white-pill {
      color: black;
      background: white;
      height: 80%;
      max-width: 240px;
      margin: 2rem 0 2rem auto;
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
      
      @media (max-width: 1000px) {
        max-width: 180px;
      }

      @media (max-width: 700px) {
        margin: 2rem auto 0;
        border-top-left-radius: 50%;
        border-top-right-radius: 50%;
        border-bottom-left-radius: 0;
        max-width: 125px;
      }
      .sponsored-by {
        font-family: Plus Jakarta Sans !important;
        margin-left: 2.5rem;
        padding-top: 3rem;
        font-size: 1.3rem;
        font-weight: 600;
        font-family: 'Orpheus Pro';

        @media (max-width: 1000px) {
          font-size: 1.2rem;
          margin-left: 2rem;
        }
        @media (max-width: 700px) {
          text-align: center;
          font-size: .9rem;
          margin-left: 0;
          padding-top: 1.5rem;
        }
      }
      .hedera {
        margin-left: 2.5rem;
        margin-top: 1rem;
        font-size: 1.2rem;
        font-weight: 700;
        display: flex;
        flex-direction: row;
        align-items: center;

        @media (max-width: 1000px) {
          font-size: .9rem;
          margin-left: 2rem;
        }

        @media (max-width: 700px) {
          text-align: center;
          margin: auto;
          padding-bottom: 2rem;
          margin-top: .5rem;
          justify-content: center;
        }
      }
    }
  }
}

.lighthouse-subscribe-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 40px 30px 0px 30px;
  font-family: Plus Jakarta Sans;
  font-size: 7.2rem;
  font-weight: 800;
  line-height: 6rem;
  margin-bottom: 30px;
  flex-wrap: wrap;
  
  @media (max-width: 700px) {
    font-size: 3.2rem;
    font-weight: 800;
    line-height: 3.5rem;
    text-align: center;
  }

  @media (min-width: 700px) {
    padding: 80px 30px 0px 30px;
  }
}

.strike-through {
  position: absolute;
  transform: translateX(-30%) translateY(-6%);
  left: 50%;
  font-size: 6rem;
  color: #FF6565;
  opacity: 0.8;
}

.free-first-edition {
  margin: 1rem auto 2rem;
  width: fit-content;
  text-transform: uppercase;
  border: 1px solid gray;
  padding: 1rem 3rem;
  border-radius: 60px;
  text-align: center;
  line-height: 2.8rem;
  width: 90%;
  max-width: 1000px;
  text-align: center;

  @media (max-width: 700px) {
    padding: 1rem 3rem;
    margin: 0rem auto 1rem;
  }

  .free-edition {
    font-size: 2rem;
    font-weight: 500;
    line-height: 2.8rem;
    letter-spacing: .8rem;
    margin: auto;
    margin-bottom: .4rem;

    @media (max-width: 700px) {
      font-size: 1rem;
      line-height: 1.4rem;
    }
  }
  .available-date {
    line-height: 1.2rem !important;
    letter-spacing: .1rem !important;
    margin-bottom: .4rem;
  }
}

.lighthouse-report-content {
  margin-bottom: 20px;
  text-align: center;
  text-transform: uppercase;
  height: fit-content;
  margin: 80px 0 60px 0;

  @media (max-width: 700px) {
    margin: 30px 0 60px 0;
  }
}

.subscription-model-wrapper {
  margin: 1rem auto;
  width: fit-content;
  border: 1px solid gray;
  padding: 1.5rem 1rem;
  border-radius: 20px;
  width: 90%;
  max-width: 1000px;

  @media (max-width: 700px) {
    padding: 1rem 1rem;
    margin: 2rem auto;
  }

  .coming-soon {
    width: fit-content;
    text-align: center;
    line-height: 1rem;
    font-size: 1.6rem;
    font-style: normal;

    .coming-soon-button {
      margin-top: 4px;
      background-color: #FF6565;
      color: white !important;
      border-radius: 10px !important;
      text-transform: uppercase;
      font-size: 1.9rem !important;
      font-weight: 700;
      line-height: 1.4rem !important;
      letter-spacing: .05rem !important;
      padding: 6px;
      width: fit-content;
    }
  }
  .free-edition {
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: .4rem;
    line-height: 2.8rem;
    letter-spacing: .8rem;
    margin: auto;
    margin-bottom: .4rem;

    @media (max-width: 700px) {
      font-size: 1rem !important;
      line-height: 1.6rem;
    }
  }

  .monthly-subscription {
    margin: 2rem auto 1rem;
    border: 1px solid gray;
    border-radius: 30px;
    display: flex;
    border-radius: 80px;
    align-items: center;
    max-width: 800px;
    
    @media (max-width: 700px) {
      margin: 10px;
      flex-direction: column;
      border-radius: 20px !important;
      padding: 10px;
    }

    .main-text {
      font-size: 3.5rem;
      font-weight: 700;
      margin-top: 1.5rem;
      letter-spacing: .2rem;
      line-height: 3rem;

      @media (max-width: 700px) {
        padding-top: 6px;
        font-size: 1.8rem;
        line-height: 2rem;
        margin: auto;
      }
    }

    .sub-text {
      color: #FF6565;
      text-align: left;
      line-height: 2rem;

      @media (max-width: 700px) {
        text-align: center;
        font-size: 1rem;
        line-height: 1.2rem;
        margin: auto;
        padding-top: 6px;
      }
    }

    .available-date {
      line-height: 1.2rem !important;
      letter-spacing: .1rem !important;
      margin-bottom: .4rem;
      text-align: left;

      @media (max-width: 700px) {
        text-align: center;
        font-size: .7rem !important;
        line-height: 2rem;
      }
    }
  }

  .subscription-content {
    max-width: 800px;
    margin: auto;
    padding: .5rem 2.5rem 1rem 2.5rem;
    text-align: left;

    @media (max-width: 700px) {
      padding: .5rem .8rem 1rem .8rem;
    }
  }

  .free-subscription {
    text-align: left;
    margin-bottom: .5rem;
    font-size: 2.4rem;
    font-weight: 700;
    text-transform: none;
    letter-spacing: .1rem;

    @media (max-width: 700px) {
      margin-top: .6rem;
      margin-bottom: .7rem;
      font-size: 1.6rem;
      line-height: 1.7rem;
    }
  }
}


.click-here {
  text-align: center;
  font-family: Plus Jakarta Sans;
  // background-color: gray !important;
  // color: white !important;
  // border-radius: 22px !important;
  text-transform: uppercase;
  font-size: 1.4rem !important;
  line-height: 1.6rem !important;
  letter-spacing: .25rem !important;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;

  @media (max-width: 700px) {
    width: 250px;
    font-size: 1.2rem !important;
  }
}

.click-here-subscribe {
  text-align: center;
  width: 700px;
  font-family: Plus Jakarta Sans;
  // background-color: gray !important;
  color: white !important;
  border-radius: 22px !important;
  text-transform: uppercase;
  font-size: 1.2rem !important;
  line-height: 1.6rem !important;
  letter-spacing: .25rem !important;

  @media (max-width: 700px) {
    width: 250px;
    font-size: 1rem !important;
    line-height: 1.2rem !important;
  }
}

.register-here {
  text-align: center;
  width: 350px;
  font-size: 1.6rem !important;
  line-height: 2.5rem !important;
  letter-spacing: .25rem !important;
  // background-color: black !important;
  font-family: Plus Jakarta Sans;
  color: white !important;
  border-radius: 30px !important;
  text-transform: uppercase;
  margin-bottom: 2rem;
  // margin-bottom: 1.5rem !important;

  @media (max-width: 700px) {
    width: 250px;
    font-size: .9rem !important;
    line-height: 1rem !important;
    margin-bottom: 1rem;
  }
}

.subscribe-below {
  font-family: Plus Jakarta Sans Extra Bold !important;
  font-size: 2.5rem;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  margin: 2rem;

  @media (max-width: 700px) {
    font-size: 2.2rem;
  }
}
.limited-offer {
  font-family: Plus Jakarta Sans;
  font-size: 1rem;
  font-weight: 200;
  text-align: center;
  margin-bottom: 3rem;
  letter-spacing: .1rem !important;

  @media (max-width: 700px) {
    font-size: .8rem;
  }
}

.lighthouse-home-page {
  z-index: 1000;
}

.normal-price {
  font-family: Plus Jakarta Sans;
  height: 150px;
  width: 150px;
  border: 1px solid black;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  margin-bottom: -4rem;
  background-color: white;
  opacity: 1;
  z-index: 10000;
  position: relative;
  text-align: center;

  @media (max-width: 700px) {
    height: 100px;
    width: 100px;
  }

  .price {
    font-size: 3.5rem;
    font-weight: 800;
    line-height: 3rem;

    @media (max-width: 700px) {
      font-size: 2rem;
      font-weight: 800;
      line-height: 1.5rem;
    }
  }

  .per-month {
    text-transform: none;
    font-weight: 600;
    font-size: 1rem;
  }
}


.monthly-price {
  font-family: Plus Jakarta Sans;
  height: 130px;
  width: 130px;
  border: 1px solid black;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: black;
  color: #FF6565;
  opacity: 1;
  z-index: 10000;
  
  @media (max-width: 700px) {
    height: 100px;
    width: 100px;
  }

  .price {
    font-size: 2.2rem;
    font-weight: 800;
    line-height: 1.5rem;
    font-family: Plus Jakarta Sans Extra Bold;

    @media (max-width: 700px) {
      font-size: 1.5rem;
      font-weight: 800;
      line-height: .8rem;
    }
  }

  .per-month {
    text-transform: none;
    font-weight: 600;
    font-size: 1rem;
    color: white;

    @media (max-width: 700px) {
      font-size: .6rem;
      line-height: 2rem;
    }
  }
}

.all-rights-reserved {
  padding: 0 2rem;
  margin-top: 3rem;
  font-size: .7rem;
  letter-spacing: .2rem;

  @media (max-width: 700px) {
    margin-top: 0rem;
    font-size: .5rem;
  }
}

.MuiDialog-paper {
  border-radius: 2rem !important;
}

.subscription-details {
  text-transform: none;
  font-family: Plus Jakarta Sans;
  font-size: 1.2rem;
  max-width: 90%;
  margin-bottom: 3rem;

  @media (max-width: 700px) {
    max-width: 100%;
    font-size: .9rem;
  }

  .report-sections {
    padding-left: 20px;

    li {
      padding: 0;
    }
  }
}

.monthly-subscription-text {
  margin: .5rem;

  @media (max-width: 700px) {
    margin: 'auto auto auto 2.5rem'
  }
}