.landing-page {
  // margin-top: 4rem;

  @media (max-width: 700px) {
    // margin: 1rem 0 0 0;
  }
}
.three-d-logo {
  margin: 0 0 0 auto;
  width: 300px;

  @media (min-width: 700px) {
    margin: 2rem 0 0 auto;
    width: 400px;
  }

  @media (min-width: 1200px) {
    margin: 2rem 0 0 auto;
    width: 400px;
    margin: 6rem 0 0 auto;
    width: 500px;
  }
}

.header {
  font-family: Plus Jakarta Sans;
  margin: 2rem 0 20px 0;
  text-align: center;
  font-size: 1.6rem;
  letter-spacing: 1.3rem;
  font-weight: 400;


  @media (min-width: 700px) {
    font-size: 3rem;
    letter-spacing: 3rem;
  }

  @media (min-width: 1200px) {
    font-size: 4rem;
    letter-spacing: 5.5rem;
  }
}

.about-us {
  width: 90%;
  max-width: 1400px;
  margin: 2rem auto 1rem auto;
  text-align: center;
  
  @media (max-width: 700px) {
    margin: 0rem auto 1rem;
  }

  .about-us-header {
    padding: 20px 40px;
    text-align: center;
    font-size: 2.5rem;
    text-transform: uppercase;
    letter-spacing: .5rem;
    font-weight: 300;

    @media (max-width: 700px) {
      font-size: 1.5rem;
      padding: 20px 40px 10px;
    }
  }

  .about-us-content {
    font-family: Plus Jakarta Sans Light;
    font-size: 1.1rem;
    font-weight: 200;
    margin-bottom: 4rem;
    margin-top: 2rem;
    line-height: 2.5rem;
    letter-spacing: .3rem;

    @media (max-width: 700px) {
      font-size: .8rem;
      line-height: 1.8rem;
      letter-spacing: .2rem;
      margin: 1rem .5rem;
    }
  }

  .founders-title {
    text-transform: uppercase;
    font-family: Plus Jakarta Sans Light;
    font-size: 2rem;
    font-weight: 200;
    letter-spacing: .3rem;
    margin: 2rem 0;
  }
}
