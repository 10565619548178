
.MuiDialog-paper {
  // border-radius: 40px !important;
  background-color: #D9D9D9;

  @media (max-width: 500px) {
    // border-radius: 0px !important;
  }

  .description {
    text-align: center;
    font-size: 1rem;
    font-family: Plus Jakarta Sans;
    margin: 0 3rem 2rem;

    @media (max-width: 700px) {
      margin: 0 1rem 2rem;
      font-size: .9rem;
    }
  }

  .media-links {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      cursor: pointer;
    }
  }
}